
	import Vue from "vue";
	import {User, UserDataCreate, Role} from "../../../../interfaces/user";
	import Alertize from "../../../../components/Alertize.vue";
	import CardTextField from "../../../../components/Content/CardTextField.vue";
	import CardAutocomplete from "../../../../components/Content/CardAutocomplete.vue";
	import { isRequired, isEmail, isNumber } from "../../../../services/rule-services";
	import { getError } from "../../../../utils/resolveObjectArray";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";

	export default Vue.extend({
		name: "UserCreate",
		props: {},
		components: { 
			Alertize,
			CardTextField,
			CardAutocomplete, 
		},
		data: () => ({
			title: "Create",
			user: {} as UserDataCreate,
			valid: true,
			email: "",
			role_id: undefined
		}),
		created() {},
		async mounted() {
			this.setLoadingData(TypeLoading.loading)
			await this.dispatchRoles();
			await this.setLoadingData();
		},
		computed: {
			getRoles(): Role[] {
				return this.$store.state.user.roles ? this.$store.state.user.roles : [];
			},
			getUser(): User {
				return this.user;
			},
			getRules() {
				return {
					isRequired,
					isEmail,
					isNumber
				};
			},
			getFails() {
				return this.$store.state.proccess.errors;
			},
		},
		methods: {
			...mapActions("loading", ["setLoadingData"]),
			getFail(index: any) {
				return getError(this.getFails, index);
			},
			setNotification(notification: Notification) {
				return this.$store.dispatch(
					"proccess/setNotification",
					notification,
					{ root: true }
				);
			},
			async validate() {
				return await this.$refs.form.validate();
			},
			async handleSubmit() {
				try {
					if (!(await this.validate())) return;
					this.setLoadingData(TypeLoading.loading)
					await this.create();
					await this.setLoadingData();
				} catch (error) {
					console.error("handleSubmit", { error: error });
					await this.setLoadingData();
				}
			},
			handleCancel() {
				this.$router.push({ name: "UsersIndex" });
			},
			async dispatchRoles() {
				return this.$store.dispatch("user/listRoles", {
					root: true,
				});
			},
			async create() {
				this.user = {
					email: this.email,
					role_id: this.role_id,
				} as UserDataCreate;
				return this.$store.dispatch("user/create", this.user, {
					root: true,
				});
			},
		},
	});
