var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"my-0"},[_c('Alertize'),_c('v-layout',{attrs:{"column":""}},[_c('v-form',{ref:"form",attrs:{"justify":"center","align":"center","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('CardTextField',{attrs:{"rules":[
								_vm.getRules.isRequired,
								_vm.getRules.isEmail 
							],"hint":_vm.$t('users.fields.email'),"reference":"email","placeholder":_vm.$t('users.fields.email'),"label":_vm.$t('users.fields.email'),"counter":255,"required":true,"error_messages":_vm.getFail('email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('CardAutocomplete',{ref:"role_id",attrs:{"rules":[
								_vm.getRules.isRequired,
								_vm.getRules.isNumber,
							],"items":_vm.getRoles,"item_text":"description","item_value":"id","hint":_vm.$t('users.fields.role'),"label":_vm.$t('users.fields.role'),"placeholder":_vm.$t('users.fields.role'),"chips":true,"deletable_chips":true,"multiple":false,"small_chips":true,"dense":false,"required":true,"error_messages":_vm.getFail('role_id')},model:{value:(_vm.role_id),callback:function ($$v) {_vm.role_id=$$v},expression:"role_id"}})],1)],1),_c('v-divider',{staticClass:"ma-4"}),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"9"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-card-text',{staticClass:"secondary--text info-message",attrs:{"align":"start"}},[_vm._v(" "+_vm._s(_vm.$t("common.labels.mandatory"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"3"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticClass:"mx-2 px-8",attrs:{"rounded":"","color":"secondary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t("common.actions.save"))+" ")]),_c('v-btn',{staticClass:"mx-2 px-8",attrs:{"rounded":"","color":"secondary"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t("common.actions.cancel"))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }